<template>
  <div id="app">
    <Navbar />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Navbar from '../src/components/Navbar.vue'
import Footer from '../src/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  }
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
  align-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 1rem;
}
#app {
  font-family: helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #575757;
  margin: 0;
  padding: 0;
  border: 0;
}
h2{
  font-family: all-round-gothic,sans-serif;
  font-weight: 700;
  font-style: normal;
}
h1{
  font-family: all-round-gothic,sans-serif;
  font-weight: 700;
  font-style: normal;
}
p {
  color:#575757;
}
a {
  color:#575757;
}
</style>
