<template>
  <div class="footer">
     <div class="footerText">
         <p>Contact: <a href="mailto: projet@menardstamand.com">projet@menardstamand.com</a></p>
         <p>&copy; <br>Ménard/St-Amand <br>Francis Lecavalier <br>Valentin Laurent</p>
         <p>Version 2.0.0</p>
         <br>
       </div>
    </div>
</template>

<script>
export default {
  name: 'Footer'

}
</script>

<style>
.footer {
  padding: 0;
  width: 100%;
  height: auto;
  font-family: all-round-gothic,sans-serif;
  font-weight: 300;
  font-style: normal;
  position: relative;
  display: inline-block;
  z-index: 10;
  }
.footerText{
  display: inline-block;
  position: absolute;
  left: 0%;
  font-size: 20px;
  width: 100%;
  height: 50px;
  color: #0d0d0e;
  font-weight: lighter;
}
a {
  text-decoration: none;
  color: #000;
}
a :active{
  text-decoration: none;
  color: #000;
;
}

a :visited{
  text-decoration: none;
  color: #000;
}

</style>
