<template>
    <nav>
       <div class="logoMenu"><router-link to="/"><img width="100%" height="auto" src="@/assets/Logo13.png"></router-link></div>
       <div class="menu-items">
        <Applications title="Applications" />
        <Projsub title="Réalisations"/>
        <div class="menu-item"><router-link to="/presse">Presse</router-link></div>
      </div>
    </nav>
</template>

<script>
import Applications from './Applications.vue'
import Projsub from './Projsub.vue'

export default {
  name: 'Navbar',
  components: {
    Applications,
    Projsub
  }
}

</script>

<style>
/* DESKTOP SIZE */
@media only screen and (min-width: 1800px) {
nav {
  width: 100%;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 1px;
}
nav .menu-item {
  color: #0d0d0e;
  padding: 10px 30px;
  position: relative;
  text-align: center;
  border-bottom: 5px solid transparent;
  display: flex;
  transition: 0.4s;
  min-width: 5ch;
}
nav .menu-items {
  width: 100%;
  display: flex;
  justify-content: right;
  margin-right: 80px;
  font-size: 35px;
  font-family: all-round-gothic,sans-serif;
  font-weight: 300;
  font-style: normal;
}
nav .menu-item.active,
nav .menu-item:hover {
  border-bottom-color: #575757;
  border-bottom-width: 2px;
  font-size: 0.9em;
}
nav .menu-item a {
  color: inherit;
  text-decoration: none;
}
.logoMenu{
  position: absolute;
  top: 40px;
  left: 50px;
  width: 300px;
  height: auto;
}
}
/* LAPTOP SIZE */
@media only screen and (min-width: 1080px) and (max-width: 1800px) {
nav {
  width: 100%;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
}
nav .menu-item {
  color: #0d0d0e;
  padding: 10px 40px;
  position: relative;
  text-align: center;
  border-bottom: 5px solid transparent;
  display: flex;
  transition: 0.4s;
  min-width: 5ch;
}
nav .menu-items {
  width: 100%;
  display: flex;
  margin-left: 65%;
  margin-right: 15ch;
  font-size: 20px;
  font-family: all-round-gothic,sans-serif;
  font-weight: 300;
  font-style: normal;
}
nav .menu-item.active,
nav .menu-item:hover {
  border-bottom-color: #575757;
  border-bottom-width: 2px;
  font-size: 0.9em;
}
nav .menu-item a {
  color: inherit;
  text-decoration: none;
}
.logoMenu{
  position: absolute;
  top: 30px;
  left: 50px;
  width: 200px;
  height: auto;
}
}
/* TABLET SIZE */
@media only screen and (min-width: 764px) and (max-width: 1080px) {
 nav {
  width: 100%;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 1px;
}
nav .menu-item {
  color: #0d0d0e;
  padding: 10px 25px;
  position: relative;
  text-align: center;
  border-bottom: 5px solid transparent;
  display: flex;
  transition: 0.4s;
  min-width: 5ch;
}
nav .menu-items {
  position: relative;
  width: 450px;
  display: flex;
  font-size: 20px;
  font-family: all-round-gothic,sans-serif;
  font-weight: 300;
  font-style: normal;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
}
nav .menu-item.active,
nav .menu-item:hover {
  border-bottom-color: #575757;
  border-bottom-width: 2px;
  font-size: 0.9em;
}
nav .menu-item a {
  color: inherit;
  text-decoration: none;
}
.logoMenu{
  position: absolute;
  top: 20px;
  margin: auto;
  width: 250px;
  height: auto;
}
}
/* MOBILE SIZE */
@media only screen and (min-width: 300px) and (max-width: 764px) {
  nav {
  width: 100%;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 1px;
}
nav .menu-item {
  color: #0d0d0e;
  padding: 10px 25px;
  position: relative;
  text-align: center;
  border-bottom: 5px solid transparent;
  display: flex;
  transition: 0.4s;
  min-width: 5ch;
}
nav .menu-items {
  position: relative;
  width: 40ch;
  display: flex;
  font-size: 18px;
  font-family: all-round-gothic,sans-serif;
  font-weight: 300;
  font-style: normal;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
}
nav .menu-item.active,
nav .menu-item:hover {
  border-bottom-color: #575757;
  border-bottom-width: 2px;
  font-size: 0.9em;
}
nav .menu-item a {
  color: inherit;
  text-decoration: none;
}
.logoMenu{
  position: absolute;
  top: 20px;
  margin: auto;
  width: 220px;
  height: auto;
}
}

</style>
