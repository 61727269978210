<template>
    <div class="menu-item" @click="isOpen = !isOpen" >
        <a href ='#'>
            {{ title }}
        </a>
        <transition name="fade" apear>
         <div class="sub-menu" v-if="isOpen">
            <div class="menu-item">
              <router-link to="/projets/realisations">Projets</router-link>
            </div>
            <div class="menu-item">
              <router-link to="/projets/en-cours">En cours</router-link>
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
export default {
  name: 'Projsub',
  props: ['title'],
  data () {
    return {
      isOpen: false
    }
  }
}
</script>

<style>
nav .menu-item svg {
    width: 10px;
    position: absolute;
    left: 15ch;
    top: 1.8ch;
}

nav .menu-item .sub-menu {
  position: absolute;
  background-color: #FFF;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  border-radius: 0px 0px 16px 16px;
  z-index: 10;
}
.fade-enter-active,
.fade-leave-active {
  transition: all .5s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.sub-menu {
  cursor: pointer;
}
@media only screen and (min-width: 1800px) {
  nav .menu-item svg {
    width: 1.2rem;
    position: absolute;
    left: 13ch;
    top: 1.3ch;
}
}
@media only screen and (max-width: 1080px){
nav .menu-item svg {
    width: 10px;
    position: absolute;
    left: 14ch;
    top: 1.8ch;
}

nav .menu-item .sub-menu {
  position: absolute;
  background-color: #FFF;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  border-radius: 0px 0px 16px 16px;
  z-index: 10;
}
.fade-enter-active,
.fade-leave-active {
  transition: all .5s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.sub-menu {
  cursor: pointer;
}
}
</style>
