import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/applications/telharmonium',
    name: 'Telharmonium 2.0',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Telharmonium.vue')
  },
  {
    path: '/projets/realisations',
    name: 'Realisations',
    component: () => import(/* webpackChunkName: "projets" */ '../views/Realisations.vue')
  },
  {
    path: '/projets/en-cours',
    name: 'Encours',
    component: () => import(/* webpackChunkName: "projets" */ '../views/Encours.vue')
  },
  {
    path: '/applications',
    name: 'Applications',
    component: () => import(/* webpackChunkName: "projets" */ '../components/Applications.vue')
  },
  {
    path: '/presse',
    name: 'Presse',
    component: () => import(/* webpackChunkName: "projets" */ '../views/Presse.vue')
  },
  {
    path: '/applications/on-off',
    name: 'OnOff',
    component: () => import(/* webpackChunkName: "projets" */ '../views/OnOff.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
